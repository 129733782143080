
// Example of a variable override to change Front's primary color and font size
// Remove the "//" to comment it in and see it in action!
// $primary: $red;
// $font-size-base: .875rem;

// Front Default Color Variables
// =========================================================

$root-path:                                                 "../";



// Grays
$gray-100:                                                  #f7faff;
$gray-200:                                                  #f8fafd;
$gray-300:                                                  #e7eaf3;
$gray-400:                                                  #bdc5d1;
$gray-500:                                                  #97a4af;
$gray-600:                                                  #8c98a4;
$gray-700:                                                  #677788;
$gray-800:                                                  #71869d;
$gray-900:                                                  #1e2022;

// Colors
$white:                                                     #fff;
$dark:                                                      #21325b;
$blue:                                                      #377dff;
$teal:                                                      #00c9a7;
$cyan:                                                      #09a5be;
$yellow:                                                    #f5ca99;
$red:                                                       #ed4c78;
$green:                                                     #4bb962;

// Theme Colors
$primary:                                                   $green;
$secondary:                                                 $gray-800;
$success:                                                   $green;
$info:                                                      $cyan;
$warning:                                                   $yellow;
$danger:                                                    $red;
$dark:                                                      $dark;

// Theme Soft Colors
$primary-soft:                                              rgba($primary, .1);
$secondary-soft:                                            rgba($secondary, .1);
$success-soft:                                              rgba($success, .1);
$info-soft:                                                 rgba($info, .1);
$warning-soft:                                              rgba($warning, .1);
$danger-soft:                                               rgba($danger, .1);
$light-soft:                                                rgba($gray-100, .1);
$dark-soft:                                                 rgba($dark, .1);



// The color of the SVG loader should be modified manually.
// See assets/svg/components/circle-preloader.svg
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v29-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/open-sans-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v29-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/open-sans-v29-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/open-sans-v29-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v29-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v29-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v29-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v29-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}



$primary:                                                   #00985e;
$font-family-base:                                          "Open Sans", sans-serif;

// Colors
$body-color:                                                #25282e!important;

// Avatar sizes

$avatar-width-xxl :                                         10rem;
$avatar-height-xxl:                                         10rem;


h2 { color: $primary}