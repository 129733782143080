// user-variables.scss
// Use this to overwrite Bootstrap and Front variables
//


/* raleway-300 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/raleway-v28-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/raleway-v28-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/raleway-v28-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/raleway-v28-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/raleway-v28-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/raleway-v28-latin-300.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-700 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/raleway-v28-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/raleway-v28-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/raleway-v28-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/raleway-v28-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/raleway-v28-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/raleway-v28-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
  }

  h1, h2, h3, h4, h5, h6, 
  .h1, .h2, .h3, .h4, .h5, .h6 {

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    color: #2a6387!important;
  }
.navbar {
    background-color: #00a2e7;
    font-family: 'Open Sans',sans-serif;
    margin-bottom: 0;
    font-weight: 500;
    min-height: 77px;
}

.woocommerce-billing-fields__field-wrapper .woocommerce-input-wrapper  {

    padding-right:12px!important;
    display: block!important;
    
}

p#billing_company_field {
    width:100%!important;
}

#crf_cb_anonymous_field {
    display:none;
}

.crf_contribution_btn_active {
    color: #fff!important;
    background: #E84B3B!important;
border-color:#E84B3B!important;
    
    box-shadow: 0 .1875rem .375rem rgba(140,152,164,.25)!important;

}